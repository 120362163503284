import React, { useEffect, useState } from "react";
import "firebase/compat/database";
import firebase from "../utils/Firebase";
import CardCom from "./CardCom";
import styled from "styled-components";
import PaginationItem from "./PagginationItem";
import SearchBar from "./SearchBar";

const StyledDiv = styled.div`
  display: flex !important;
  justify-content: center;
`;

const BodyDiv = styled.div``;

function fnBrowserDetect() {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName;
}

const Body = () => {
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(36);
  const [rumpleListOriginal, setrumpleListOriginal] = useState<any[]>([]);
  const [rumpleList, setrumpleList] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    const rumple = firebase.database().ref("Rumpels");
    const saved = localStorage.getItem("Rumples");
    if (saved === null) {
      const rumple = firebase.database().ref("Rumpels");
      if (localStorage)
        rumple.once("value", async (snapshop) => {
          const rumples = await snapshop.val();
          const rumpleList = [];
          for (let id in rumples) {
            delete rumples[id].description;
            rumpleList.push(rumples[id]);
          }
          setrumpleList(rumpleList);
          setrumpleListOriginal(rumpleList);
          localStorage.setItem("Rumples", JSON.stringify(rumpleList));
          setLoading(false);
        });
    } else {
      setrumpleList(JSON.parse(saved));
      setrumpleListOriginal(JSON.parse(saved));
      setLoading(false);
    }
  }, []);

  let indexOfLastCard = activePage * cardsPerPage;
  let indexOfFirstCard = indexOfLastCard - cardsPerPage;
  let currentCard = rumpleList.slice(indexOfFirstCard, indexOfLastCard);
  const paginate = (pageNumber: React.SetStateAction<number>) => {
    setActivePage(pageNumber);
    window.scrollTo(0, 0);
  };
  const browser = fnBrowserDetect();
  return (
    <BodyDiv>
      <SearchBar
        setrumpleList={setrumpleList}
        rumpleListOriginal={rumpleListOriginal}
      />

      {loading && browser === "safari" ? (
        <h1>If the content does not appear, use another browser or go to Settings {">"} Safari {">"} Hide IP Address {">"} toggle OFF. Then reload the page</h1>
      ) : loading ? (
        <StyledDiv>
          <h2>Loading ..</h2>
          <div style={{ paddingBottom: "30%" }}></div>
        </StyledDiv>
      ) : (
        <>
          <StyledDiv>
            <CardCom rumpleleList={currentCard} />
          </StyledDiv>
          <PaginationItem
            cardsPerPage={cardsPerPage}
            totalCards={rumpleList.length}
            paginate={paginate}
            actualpage={activePage}
          />
        </>
      )}
    </BodyDiv>
  );
};

export default Body;
