/* @ts-ignore */
import { slide as Menu } from "react-burger-menu";
import theme from "../theme/theme";

type PropsHamburgerMenu = {
  pageWrapId: string;
  outerContainerId: string;
};

var styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "28px",
    height: "28px",
    right: "30px",
    top: "25px",
  },
  bmBurgerBars: {
    height: "12%",
    background: "black",
  },
  bmBurgerBarsHover: {
    background: theme.colorsInvert.primary,
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    height: "24px",
    background: theme.colorsInvert.text,
  },
  bmCrossHover: {
    background: theme.colorsInvert.primary,
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: theme.colorsInvert.background,
    padding: "30px",
    fontSize: theme.fontSizeElements.h3,
  },
  bmMorphShape: {
    fill: theme.colorsInvert.text,
  },
  bmItem: {
    display: "block",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "1.6em",
    color: theme.colorsInvert.text,
    transform: theme.animation.transitionBasic,
  },
  bmItemHover: {
    textDecoration: "none",
    color: theme.colorsInvert.primary,
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

function HamburgerMenu({ pageWrapId, outerContainerId }: PropsHamburgerMenu) {
  return (
    // Pass on our props
    <Menu
      pageWrapId={pageWrapId}
      outerContainerId={outerContainerId}
      styles={styles}
      right
      isOpen={false}
    >
      <a
        className="menu-item"
        href="https://rumpelcrew.com/#about"
        target="_blank"
      >
        About
      </a>
      <a
        className="menu-item"
        href="https://rumpelcrew.com/#nft-collection"
        target="_blank"
      >
        NFT collection
      </a>
      <a
        className="menu-item"
        href="https://rumpelcrew.com/#team"
        target="_blank"
      >
        Team
      </a>
      <a
        className="menu-item"
        href="https://rumpelcrew.com/#roadmap"
        target="_blank"
      >
        Roadmap
      </a>
      <a
        className="menu-item"
        href="https://rumpelcrew.com/#rumpel-life"
        target="_blank"
      >
        Rumpel life
      </a>
      {/* <a
        className="menu-item"
        href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        target="_blank"
      >
        Rumpel rarity
      </a> */}
    </Menu>
  );
}

export default HamburgerMenu;
