import { useState } from "react";
import styled from "styled-components"; // @ts-ignore
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Divider } from "@mui/material";
import "firebase/compat/database";
import "../App.css";
import { GteDesktop, GteTablet, LteTablet, Mobile } from "../utils/MediaQueries";
import placeholder from "../img/8.png";

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 2800px;
`;

const Rarita = styled.span`
  font-family: ${(props) => props.theme.fonts.heading};
  font-weight: bold;
  font-size: ${props => props.theme.fontSizeElements.h3}px;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    font-size: ${props => props.theme.fontSizeElements.h3}px;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    font-size: ${props => props.theme.fontSizeElements.body1}px;
  }
`;

const RaritaNotBold = styled.span`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: ${props => props.theme.fontSizeElements.h3}px;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    font-size: ${props => props.theme.fontSizeElements.h3}px;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    font-size: ${props => props.theme.fontSizeElements.body1}px;
  }
`;


interface ObjCards {
  rumpleleList: any[];
}

const CardCom = ({ rumpleleList }: ObjCards) => {
  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <CardsWrapper>
        {rumpleleList.map((card, index) => (
          <Card
            key={card.TokenID}
            sx={{
              maxWidth: 350,
              opacity: 1,
              boxShadow: "3px 3px",
              border: "1px solid",
            }}
            className="CardCss"
          >
            <CardActionArea
              onClick={() =>
                openInNewTab(
                  "https://opensea.io/assets/ethereum/0x956617fc1e05a4cfc8c7911dfaa0a2c188f9aa03/" +
                    card.TokenID
                )
              }
            >
              <CardContent style={{ borderLeft: "1px solid" }}>
                <LteTablet>
                  <Typography gutterBottom variant="body2" component="div">
                    <Rarita>
                      Rarity {card.RarityNumber}{" "}
                    </Rarita>{" "}
                    <RaritaNotBold>
                      / 700
                    </RaritaNotBold>
                  </Typography>
                </LteTablet>
                <GteDesktop>
                  <Typography gutterBottom variant="h5" component="div">
                    <Rarita>
                      Rarity {card.RarityNumber}{" "}
                    </Rarita>{" "}
                    <RaritaNotBold>
                    / 700
                    </RaritaNotBold>
                  </Typography>
                </GteDesktop>
                <Divider />
              </CardContent>
              <LteTablet>
                <CardMedia
                  component="img"
                  image={card.image}
                  alt={card.name}
                  sx={{height: "150px",borderLeft:"1px solid", width: "150px"}}
                />
              </LteTablet>
              <GteDesktop>
              <CardMedia
                component="img"
                image={card.image}
                //image={placeholder}
                alt={card.name}
                className="CardMediaCustomCss"
              />
              </GteDesktop>
              <CardContent style={{ borderLeft: "1px solid" }}>
                <Divider />
                <LteTablet>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    sx={{ paddingTop: 2 }}
                  >
                    {String(card.Name).toUpperCase()}
                  </Typography>
                </LteTablet>
                <GteDesktop>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ paddingTop: 2 }}
                  >
                    <RaritaNotBold>
                    {String(card.Name).toUpperCase()}
                    </RaritaNotBold>
                  </Typography>
                </GteDesktop>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </CardsWrapper>
    </div>
  );
};

export default CardCom;
