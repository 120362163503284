import styled from "styled-components";
import { Flex, Box, Link, Image, Button } from "rebass";
import SocialLinks from "./SocialLinks";
import { GteDesktop } from "../utils/MediaQueries";

const Topbar = styled(Flex)`
  display: flex;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: ${(props) => props.theme.layouts.xl};
  height: 120px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 170px !important;
  padding-right: 170px !important;
  background-color: transparent;
  color: ${(props) => props.theme.colorsInvert.text};
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    height: 100px;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    height: 80px;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
`;
const Logo = styled(Image)`
  width: 225px;
  height: 87px;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 187px;
    height: 72px;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    width: 150px;
    height: 58px;
  }
`;
const Menu = styled(Box)`
  display: none;
  @media screen and (${(props) => props.theme.breakpoints.gteDesktopQuery}) {
    display: block;
  }
`;

const Navbar = () => {
  return (
    <>
      <Topbar px={2} alignItems="center">
        <Link href="/">
          <Logo src="./images/logo.png" alt="Rumpel" />
        </Link>
        <Menu mx="auto"></Menu>
        <div>
          <GteDesktop>
            <>
              <SocialLinks
                iconSize="lg"
                instagram="https://www.instagram.com/rumpel.crew/"
                twitter="https://twitter.com/rumpelcrew"
                opensea="https://opensea.io/collection/rumpelcrew"
                color="dark"
              />
            </>
          </GteDesktop>
        </div>
      </Topbar>
    </>
  );
};

export default Navbar;
