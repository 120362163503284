import firebase from "firebase/compat/app";
import getAuth from "firebase/compat/auth";
const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

declare global {
  // var must be used for global scopes
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#type-checking-for-globalthis
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

// const firebaseConfig = {
//     apiKey: "AIzaSyBkQfO5nYHzdiYVYWyea7EyfTfP5gMPVog",
//     authDomain: "rumples-41c67.firebaseapp.com",
//     projectId: "rumples-41c67",
//     storageBucket: "rumples-41c67.appspot.com",
//     messagingSenderId: "414304645720",
//     appId: "1:414304645720:web:0c478d5ea78dd1b219102d"
//   };

const firebaseConfig = {
  apiKey: "AIzaSyDgtEd6FLW1CCId9LtzUqpsgbJOgdwZc_U",

  authDomain: "rumpelrarity.firebaseapp.com",

  databaseURL:
    "https://rumpelrarity-default-rtdb.europe-west1.firebasedatabase.app",

  projectId: "rumpelrarity",

  storageBucket: "rumpelrarity.appspot.com",

  messagingSenderId: "967019875730",

  appId: "1:967019875730:web:073b1e4233be5b6cddc54b",
};

const app = firebase.initializeApp(firebaseConfig);
//self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_SECRET_TOKEN),
});

export default firebase;
