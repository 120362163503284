import styled from "styled-components";
import { Heading } from "rebass";
// @ts-ignore
import { LteTablet } from "../utils/MediaQueries";

const HeadingWrapper = styled(Heading)`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: ${props => props.theme.fontSizeElements.h1}px;
  margin-bottom: 40px;
  line-height: 1em;
  color: black;
  display: inline-block;
  max-width: 1500px;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    font-size: ${props => props.theme.fontSizeElements.h1}px;
    margin: 30px 0 60px;
    line-height: 0.9em;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    font-size: ${props => props.theme.fontSizeElements.h2}px;
    margin: 20px 0 40px;
    line-height: 0.9em;
  }
`;


function HeadingCom() {
  return (
      <HeadingWrapper as="h1">
        <br />EVERY RUMPEL IS RARE.{" "} <br/>
        DON'T FORGET THAT{" "}
        <LteTablet>
          <br />
        </LteTablet>
        THE LAST WILL BE FIRST,{" "}
        <LteTablet>
          <br />
        </LteTablet>
        AND THE FIRST LAST. 
      </HeadingWrapper>
  );
}

export default HeadingCom;
