import "firebase/compat/database";
import styled from "styled-components";
import Button from "@mui/material/Button";
import ButtonArrow from "../img/ButtonArrow.png";
import React, { Dispatch, SetStateAction, useState } from "react";
import { GteDesktop } from "../utils/MediaQueries";

interface ObjItems {
  cardsPerPage?: any;
  totalCards?: any;
  paginate?: any;
  actualpage?: any;
}

const ButtonStyled = {
  backgroundColor: "#bd091f",
  fontSize: "20px",
  padding: "10px",
  margin: "10px",
  color: "white",
  maxWidth: "250px",
  maxHeight: "55px",
  minWidth: "250px",
  minHeight: "40px",
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: none;
`;

const PaginationItem = ({
  cardsPerPage,
  totalCards,
  paginate,
  actualpage,
}: ObjItems) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalCards / cardsPerPage); i++) {
    pageNumbers.push(i);
  }


  let notFirstPage = false;
  let LastPage = false;
  let SinglePage = false;
  if (actualpage > 1 && pageNumbers.length > 1) {
    notFirstPage = true;
  }
  if (
    actualpage === pageNumbers[pageNumbers.length - 1] &&
    pageNumbers.length > 1
  ) {
    LastPage = true;
  }
  if (pageNumbers.length === 1) {
    SinglePage = true;
  }

  if(actualpage > pageNumbers.length){
    paginate(1);
  }

  return (
    <div style={{ paddingTop: 40, paddingBottom: 200 }}>
      {SinglePage ? (
        <></>
      ) : LastPage ? (
        <div >
          <div>
        <Button
          style={ButtonStyled}
          size="large"
          onClick={() => paginate(actualpage - 1)}
        >
          <StyledDiv>
            <img
              src={ButtonArrow}
              style={{
                width: "40px",
                transform: "rotate(180deg)",
                paddingLeft: "10px",
              }}
            />
            <span style={{ paddingTop: 5 }}>Previous page</span>
          </StyledDiv>
        </Button>
        </div>
        <GteDesktop>
        <div style={{position: "relative",float: "right",marginRight:"9.5rem"}}>
        page {actualpage} of {pageNumbers.length}
        </div>
        </GteDesktop>
        </div>
      ) : notFirstPage ? (
        <div>

          <div>
          <Button
            style={ButtonStyled}
            size="large"
            onClick={() => paginate(actualpage - 1)}
          >
            <StyledDiv>
              <img
                src={ButtonArrow}
                style={{
                  width: "40px",
                  transform: "rotate(180deg)",
                  paddingLeft: "10px",
                }}
              />
              <span style={{ paddingTop: 5 }}>Previous page</span>
            </StyledDiv>
          </Button>
          <Button style={ButtonStyled} onClick={() => paginate(actualpage + 1)}>
            {" "}
            <StyledDiv>
              <span style={{ paddingTop: 3 }}>Next page</span>
              <img
                src={ButtonArrow}
                style={{ width: "40px", paddingLeft: "10px" }}
              />
            </StyledDiv>
          </Button>{" "}
      </div>
      <GteDesktop>
      <div style={{position: "relative",float: "right",marginRight:"9.5rem"}}>
        page {actualpage} of {pageNumbers.length}
        </div>
      </GteDesktop>
        </div>
      ) : (
        <div>
          <div>
        <Button style={ButtonStyled} onClick={() => paginate(actualpage + 1)}>
          {" "}
          <StyledDiv>
            <span style={{ paddingTop: 3 }}>Next page</span>
            <img
              src={ButtonArrow}
              style={{ width: "40px", paddingLeft: "10px" }}
            />
          </StyledDiv>
        </Button>
        </div>
        <GteDesktop>
        <div style={{position: "relative",float: "right",marginRight:"9.5rem"}}>
        page {actualpage} of {pageNumbers.length}
        </div>
        </GteDesktop>
        </div>
        
      )}
    </div>
  );
};

export default PaginationItem;
