import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { Dispatch, SetStateAction, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import "../App.css";
import styled from "styled-components";

interface SearchBarProps {
  setrumpleList: Dispatch<SetStateAction<any[]>>;
  rumpleListOriginal: any[];
}

type SearcherTest = {
  Birthday: String;
  Character: String;
  City: String;
  Name: String;
  PigeonName: String;
  PigeonName__1: String;
  PigeonName__2: String;
  PigeonName__3: String;
  PigeonName__4: String;
  PigeonName__5: String;
  Rarity: String;
  RumpelName: String;
  SprayName: String;
  image: String;
  RarityNumber: any;
};

const DivWrapper = styled.div`
  background-image: url(./images/pigeon.png);
  background-repeat: no-repeat;
  background-position: 95% 85%;
  background-size: 5%;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    background-image: none;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    background-image: none;
  }
`;

const SearchBox = styled.div`
  width: 800px;
  max-width: 100%;
  margin: auto;
  padding-bottom: 40px;
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    width: 85%;
  }
`;

const SearchBar = ({ setrumpleList, rumpleListOriginal }: SearchBarProps) => {

  function capitalizeWords(arr: any[]) {
    return arr.map(element => {
      return element.replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase());
    });
  }

  function isNumeric(val: any): boolean {
    return !(val instanceof Array) && val - parseFloat(val) + 1 >= 0;
  }
  const UpdateState = (e: { target: { value: any } }) => {
    let myArray = e.target.value.split(",");
    myArray = capitalizeWords(myArray)
    //console.log(myArray)
    let helparray: any[] = [];
    if (e.target.value === "") {
      setrumpleList(rumpleListOriginal);
    } else {
      myArray.map((item: string) => {
        if (item != "") {
          let AndArray = item.split("&");
          AndArray = capitalizeWords(AndArray)
          //console.log(AndArray)
          //console.log(AndArray)
          if (AndArray.length > 1) {
            rumpleListOriginal.filter((rumpel: SearcherTest) => {
              let arraytest: any[] = [];
              AndArray.map((item: string) => {
                for (const [key, value] of Object.entries(rumpel)) {
                  if (typeof value === "string") {
                    if (value === item) {
                      arraytest.push(true);
                    }
                  }
                }
              });
              if (arraytest.length === AndArray.length) {
                helparray.push(rumpel);
              }
            });

            helparray = helparray.filter((element, index) => {
              return helparray.indexOf(element) === index;
            });
            setrumpleList(helparray);
          }

          if (!isNumeric(item)) {
            if (item === "") {
              setrumpleList(rumpleListOriginal);
            }
            rumpleListOriginal.filter((rumpel) => {
              for (const [key, value] of Object.entries(rumpel)) {
                if (typeof value === "string") {
                  if (value.includes(item)) {
                    helparray.push(rumpel);
                    break;
                  }
                }
              }
            });
          } else {
            rumpleListOriginal
              .filter((rumpel) => rumpel.TokenID === Number(item))
              .map((rumpel) => helparray.push(rumpel));
          }
        }
      });
      helparray = helparray.filter((element, index) => {
        return helparray.indexOf(element) === index;
      }); // Duplicity removal
      setrumpleList(helparray);
      helparray.sort((a:SearcherTest,b:SearcherTest) => a.RarityNumber - b.RarityNumber)  // sorting of rumpels
    }
  };

  return (
    <DivWrapper>
      <SearchBox>
        <TextField
          fullWidth
          id="fullWidth"
          onChange={UpdateState}
          className="inputRounded"
          placeholder="Search for your Rumpel or Rumpelina ID, name or any trait. For a combination, use & or a comma."
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </SearchBox>
    </DivWrapper>
  );
};

export default SearchBar;
