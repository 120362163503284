import { useState } from "react";
import { ThemeProvider } from "styled-components";
import theme from "../theme/theme";
import "../styles/style.css";
import Footer from "./Footer";
import { LteTablet } from "../utils/MediaQueries";
import Navbar from "./Navbar";
import HeadingCom from "./HeadingCom";
import { useEffect } from "react";
import styled from "styled-components";
import { Button } from "rebass";
import "firebase/compat/database";
import Body from "./Body";
import FooterLogo from "../img/footerpic.png";
import { backgroundTransitions } from "react-hover-css";
import HamburgerMenu from "./HamburgerMenu";
import Fold from "./Fold";

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    max-width: 150%;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    max-width: 250%;
  }
`;

function MainMenu() {
  return (
    <ThemeProvider theme={theme}>
      <Fold />
      <div id="outer-container">
        <LteTablet>
          <HamburgerMenu
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
          />
        </LteTablet>
        <main id="page-wrap">
          <Navbar />
          <HeadingCom />
          <Body />
          <StyledImg src={FooterLogo}></StyledImg>
          <Footer />
        </main>
      </div>
    </ThemeProvider>
  );
}

export default MainMenu;
